import React, { useState } from 'react';
import ReactTable from 'react-table-v6';
import treeTableHOC from 'react-table-v6/lib/hoc/treeTable';
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import 'react-table-v6/react-table.css';
import * as data from './ReacTableData';
import BreadCrumbs from '../../layouts/breadcrumbs/BreadCrumbs';

import ComponentCard from './ComponentCard';

const TreeTable = treeTableHOC(ReactTable);

const TrainedModelTable = () => {
  const [modal, setModal] = useState(false);
  const [obj, setObj] = useState({});
  const { treedata } = data;

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <div>
      {/*--------------------------------------------------------------------------------*/}
      {/* Start Inner Div*/}
      {/*--------------------------------------------------------------------------------*/}

      {/*--------------------------------------------------------------------------------*/}
      {/* Tree table*/}
      {/*--------------------------------------------------------------------------------*/}
      <ComponentCard title="Trained Models">
        <TreeTable
          filterable
          defaultFilterMethod={(filter, row) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined
              ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
              : true;
          }}
          data={treedata}
          pivotBy={['model_name', 'revision']}
          columns={[
            // we only require the accessor so TreeTable
            // can handle the pivot automatically
            {
              accessor: 'model_name',
            },
            {
              accessor: 'revision',
            },

            // any other columns we want to display
            {
              Header: 'Build Date',
              accessor: 'build_date',
            },
            {
              Header: 'Number of Classes',
              accessor: 'no_of_classes',
            },
            {
              Header: 'Download Link',
              accessor: 'download_link',
            },
          ]}
          defaultPageSize={5}
          SubComponent={(row) => {
            // a SubComponent just for the final detail
            const columns = [
              {
                Header: 'Property',
                accessor: 'property',
                width: 200,
                Cell: (ci) => {
                  return `${ci.value}:`;
                },
                style: {
                  backgroundColor: '#DDD',
                  textAlign: 'right',
                  fontWeight: 'bold',
                },
              },
              { Header: 'Value', accessor: 'value' },
            ];
            const rowData = Object.keys(row.original).map((key) => {
              return {
                property: key,
                value: row.original[key].toString(),
              };
            });
            return (
              <div style={{ padding: '10px' }}>
                <ReactTable
                  data={rowData}
                  columns={columns}
                  pageSize={rowData.length}
                  showPagination={false}
                />
              </div>
            );
          }}
        />
      </ComponentCard>
    </div>
  );
};

export default TrainedModelTable;
