import React, { useState } from "react";
// import { Row, Col } from "react-bootstrap";
import CustomerPortal from "./Customer-comp";
//import { ResetPassword } from "../../components/password-reset/PasswordReset.comp";
//import DashboardBgImg from "./dist/images/dashboard-bg.jpg";
//import BreadCrumbs from "./layouts/breadcrumbs/BreadCrumbs";

import Typography from '@mui/material/Typography';

import { getUser, resetUserSession } from "./service/AuthService";
import { useNavigate } from "react-router-dom";
import { Stack, Button } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';

import TopCards from './components/dashboard/TopCards';
import Cards from './components/dashboard/Cards';
import ModelManagerAgent from './components/dashboard/ModelManagerAgent';
import VendorPriceTable from './components/dashboard/VendorPriceTable';


import "./styles/Customer.scss";
import "./dist/css/style.min.css";

const Customer = () => {

	const user = getUser();
	const name = user !== 'undefined' && user ? user.name : '';
	const username = user !== 'undefined' && user ? user.username : '';
	const subscriptions = user !== 'undefined' && user ? user.subscriptions : '';
	const subscriptionList = subscriptions.split(',');

	const [frmLoad, setFrmLoad] = useState("customer");
	let history = useNavigate();
	const logoutHandler = () => {
	  resetUserSession();
	  history('/');
	}
    //	const handleOnResetSubmit = e => {
//		e.preventDefault();
//	};

	const formSwitcher = frmType => {
		setFrmLoad(frmType);
	};

	return (
		<div className="customer-page" >
    	{/* <BreadCrumbs /> */}
		<Typography variant="h5" gutterBottom align="center">Hello <b>{name}</b>.</Typography>
        <Typography variant="h6" gutterBottom align="center">Welcome to your personalized dashboard!</Typography>
		<br></br>
		<Cards />
		<br></br>
      	<TopCards />
		<br></br>

		{subscriptionList.includes("AI Model Building") ? (
			<ModelManagerAgent />
          ) : (
			<br></br>
          )}
		<br></br>
		{subscriptionList.includes("Product Pricing") ? (
			<VendorPriceTable />
          ) : (
			<br></br>
          )}

		{frmLoad === "customer" && <CustomerPortal formSwitcher={formSwitcher} />}
		<br></br>
		</div>

	);
};

export default Customer;