import { Row, Col } from 'reactstrap';

import TopCardsData from './TopCardsData';
import icon1 from '../../assets/images/dashboard/icons/income.png';
import icon2 from '../../assets/images/dashboard/icons/expense.png';
import icon3 from '../../assets/images/dashboard/icons/assets.png';
import icon4 from '../../assets/images/dashboard/icons/staff.png';

const TopCards = () => {
  return (
    <Row>
      <Col sm="6" lg="3">
        <TopCardsData img={icon1} title="10,000" subtitle="Total Credits" />
      </Col>
      <Col sm="6" lg="3">
        <TopCardsData img={icon2} title="6,500" subtitle="Credits Used" />
      </Col>
      <Col sm="6" lg="3">
        <TopCardsData img={icon3} title="3,500" subtitle="Credit Balance" />
      </Col>
      <Col sm="6" lg="3">
        <TopCardsData img={icon4} title="12/15/2023" subtitle="Expires On" />
      </Col>
    </Row>
  );
};

export default TopCards;
