import React, { useState } from 'react';
import { Row, Col } from "react-bootstrap";
import Typography from '@mui/material/Typography';
import { Stack, TextField, Button } from "@mui/material";
import axios from 'axios';

import "./styles/ContactUs.css";

const sendEmailURL = "https://tmbt9488pd.execute-api.us-east-1.amazonaws.com/prod/contactus"

const ContactUs= () => {
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [formStatus, setFormStatus] = useState('')
  
  const onSubmit = (e) => {
    e.preventDefault()
    setFormStatus('Submitting...')

    if  (name.trim() === '' || email.trim() === '' || message.trim() === '') {
      setFormStatus('Please provide all information');
      return;
    }
    setFormStatus(null);

    const requestConfig = {
      headers: {
          'x-api-key': 'ujohgjsp2A9TzwOv6eGZp8EiGy71gXyx3w5Xkhhw'
      }
    }
    const requestBody = {
        name: name,
        email: email,
        message: message
    }
    
    // console.log(requestBody);

    axios.post(sendEmailURL, requestBody, requestConfig).then(response => {
      setFormStatus('Message sent.');
      console.log(response);
    }).catch(error => {
        if (error.response.status === 401 || error.response.status === 403) {
            setFormStatus(error.response.data.message);
        } else{
            setFormStatus('sorry... the backend server is down!! please try again later');
        }
    })
  };

  return (
    <div className="contactus-page">
        <Stack spacing={2}>
            <Row>
			    <Col>
                    <Typography variant="h6" gutterBottom><b>Contact Us</b></Typography>
                    <form onSubmit={onSubmit}>
                        <Stack direction='column' spacing={2}>
                            <TextField label='name' type='text' onChange={event => setName(event.target.value)}/>
                            <TextField label='email' type='text' onChange={event => setEmail(event.target.value)}/>
                            <TextField label='message' type='text' multiline onChange={event => setMessage(event.target.value)}/>
                            <Button variant="contained" type="submit">SEND</Button>
                        </Stack>
                    </form>
                    {formStatus && <p className="errorMessage">{formStatus}</p>}
                    <br></br>
                </Col>
			</Row>
        </Stack>
    </div>
  );
};
export default ContactUs