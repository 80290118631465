import React from 'react';

import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  FormText,
  Label,
  InputGroup,
  InputGroupText,
  Button,
  FormFeedback,
} from 'reactstrap';
// import BreadCrumbs from '../../layouts/breadcrumbs/BreadCrumbs';

import ComponentCard from './ComponentCard';
import TrainedModelTable from './TrainedModelTable';

const ModelManagerAgent = () => {
  return (
    <div>
      
      {/*--------------------------------------------------------------------------------*/}
      {/* Start Inner Div*/}
      {/*--------------------------------------------------------------------------------*/}
      <Row>
        <Col sm="12" md="6">
          <ComponentCard 
            title="Upload Data for Model Building"
            subtitle={
              <p>
                Upload a single zip file, containing the class folders <code>separated into individual class folders,</code> for model building.
              </p>
              }
            >
            <Form>
              <InputGroup>
                <div className="custom-file">
                  <Input type="file" className="custom-file-input" id="customFile3" />
                </div>
                <Button color="outline-secondary">Upload</Button>
              </InputGroup>
            </Form>
          </ComponentCard>
        </Col>
        <Col sm="12" md="6">
          <ComponentCard 
            title="Build Specifications"
            subtitle={
              <p>
                Provide model build specifications in the <code>specified format</code> to generate a cost estimate.
              </p>
              }
            >
            <Form>
              <InputGroup>
                <div className="custom-file">
                  <Input type="file" className="custom-file-input" id="customFile3" />
                </div>
                <Button color="outline-secondary">Upload</Button>
              </InputGroup>
            </Form>
          </ComponentCard>
        </Col>        
      </Row>
      {/*--------------------------------------------------------------------------------*/}
      {/*End Inner Div*/}
      {/*--------------------------------------------------------------------------------*/}
      <br></br>
      <TrainedModelTable />
    </div>
  );
};

export default ModelManagerAgent;
