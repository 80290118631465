import * as React from 'react';

import { getUser, resetUserSession } from "./service/AuthService";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';



import "./styles/Customer.scss";
//export default function EnhancedTable() {
const CustomerPortal = (props) => {

  let history = useNavigate();
  const logoutHandler = () => {
    resetUserSession();
    history('/');
}

  // console.log({selectedDateRange})

  return (
    <>

    <div className="customer-page">
      
    

    <hr />
    <div className="center">
        <Button variant="outlined" startIcon={<LogoutIcon />} type="submit" sx={{ width: 200 }} onClick={logoutHandler}><b>Logout</b></Button>
    </div>    
    </div>
 </>
  )

}

export default CustomerPortal;