import React, { useState } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import * as data from './ReacTableData';

import ComponentCard from './ComponentCard';

const VendorPriceTable = () => {
  const [modal, setModal] = useState(false);
  const [obj, setObj] = useState({});
  const data3 = data.makeData();
  const { vendordata } = data;

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <div>
      <ComponentCard title="Used Golf Ball Market Data">
        <ReactTable
          data={vendordata}
          filterable
          columns={[
            {
              Header: 'Seller Information',
              columns: [
                {
                  Header: 'Seller',
                  accessor: 'seller',
                },
                {
                  Header: 'Marketplace',
                  accessor: 'marketplace',
                },
                {
                  Header: 'ASIN',
                  accessor: 'asin',
                },                
              ],
            },
            {
              Header: 'Product Information',
              columns: [
                {
                  Header: 'Model',
                  accessor: 'model',
                },
                {
                  Header: 'Grade',
                  accessor: 'grade',
                },                
                {
                  Header: 'Quantity',
                  accessor: 'quantity',
                },
                {
                  Header: 'Price',
                  accessor: 'price',
                },                
              ],
            },
            {
              Header: 'Inventory and Ratings',
              columns: [
                {
                  Header: 'Availability',
                  accessor: 'availability',
                },
                {
                  Header: 'Rating',
                  accessor: 'rating',
                },
                {
                  Header: 'Link',
                  accessor: 'link',
                },                
              ],
            },
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </ComponentCard>
    </div>
  );
};

export default VendorPriceTable;
