import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./Home";
import Register from "./Register";
import Login from "./Login";
import Customer from "./Customer";
import ContactUs from "./ContactUs";
import PublicRoutes from "./routes/PublicRoutes";
import PrivateRoutes from "./routes/PrivateRoutes";
import React, { useState, useEffect } from "react";
import { getUser, getToken, setUserSession, resetUserSession } from "./service/AuthService";
import axios from 'axios';
// import "./styles/style.css";
// import "./App.css";

const verifyTokenUrl = 'https://0w7jd4grfb.execute-api.us-east-1.amazonaws.com/prod/verify';

function App() {

  const [isAuthenticating, setAuthenticating] = useState(null)

  useEffect(() => {
    const token = getToken();
    if (token === 'undefined' || token === undefined || token === null || !token) {
      return;
    }

    const requestConfig = {
      headers: {
          'x-api-key': 'ujohgjsp2A9TzwOv6eGZp8EiGy71gXyx3w5Xkhhw'
      }
    }
    const requestBody = {
      user: getUser(),
      token: token
    }

    axios.post(verifyTokenUrl, requestBody, requestConfig).then (response => {
      setUserSession(response.data.user, response.data.token);
      setAuthenticating(false);
    }).catch(() => {
      resetUserSession();
      setAuthenticating(false);
    })
  }, []);

  const token = getToken();
  if (isAuthenticating && token) {
    return <div className="content">Authenticating...</div>
  }

  return (
    
    <div className="App">
      <Router>
      <div className="content">
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route exact path="/ContactUs" element={<ContactUs />}/>
          
          <Route element={<PublicRoutes />}>
            <Route path="/Register" element={<Register />}/>
            <Route path="/Login" element={<Login />}/>
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/Customer" element={<Customer />}/>
          </Route>
        </Routes>
        <Footer />
      </div>
      </Router>

    </div>
  );
}

export default App;
