import React, { useState } from "react";
import axios from 'axios';
import { Row, Col, Form } from "react-bootstrap";
import { Stack, TextField, Button } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
// import { useNavigate } from "react-router-dom";
import Logo from "./dist/images/tc-logo-horizontal.png";
import "./styles/Login.css";

import subscriptionNames from "./components/dashboard/subscriptionData";

const registerUrl = 'https://0w7jd4grfb.execute-api.us-east-1.amazonaws.com/prod/register';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const passVerificationError = {
    isLengthy: false,
    hasUpper: false,
    hasLower: false,
    hasNumber: false,
    hasSpclChr: false,
    passwordAgain: false,
  };

const RegisterForm = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [organization, setOrg] = useState('');
    const [address, setAddress] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordAgain, setPasswordAgain] = useState('');
    const [regCode, setRegCode] = useState('');
    const [message, setMessage] = useState(null);
    
    const [newUser, setNewUser] = useState(null);
    const [passwordError, setPasswordError] = useState(passVerificationError);

    const [subscriptions, setSubscriptions] = useState([]);
    
    const handleOnChange = (event) => {
        const { name, value } = event.target;
        setNewUser({ ...newUser, [name]: value });
       
        if (name === "password") {
            const isLengthy = value.length > 8;
            const hasUpper = /[A-Z]/.test(value);
            const hasLower = /[a-z]/.test(value);
            const hasNumber = /[0-9]/.test(value);
            const hasSpclChr = /[!,@,#,$,%,&]/.test(value);
            
            setPassword(value);
            setPasswordError({
              ...passwordError,
              isLengthy,
              hasUpper,
              hasLower,
              hasNumber,
              hasSpclChr,
            });
        }
      
        if (name === "passwordAgain") {
            setPasswordAgain(value);
            setPasswordError({
              ...passwordError,
              passwordAgain: newUser.password === value, 
            });
        }

        if (name === "subscriptions") {
          setSubscriptions(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
          );
          console.log(subscriptions);
      }
        
    };  

    const submitHandler = (event) => {
        event.preventDefault();
        if  (username.trim() === '' || email.trim() === '' || name.trim() === '' || password.trim() === '' || regCode.trim() === '') {
            setMessage('Please fill out the required fields.');
            return;
        }

        if (regCode.trim() !== 'ultimate#1!') {
            setMessage('Invalid registration code.');
            return;
        }
        setMessage(null);

        const requestConfig = {
            headers: {
                'x-api-key': 'ujohgjsp2A9TzwOv6eGZp8EiGy71gXyx3w5Xkhhw'
            }
        }
        const requestBody = {
            name: name,
            phone: phone,
            email: email,
            organization: organization,
            address: address,
            username: username,
            password: password,
            passwordAgain: passwordAgain,
            subscriptions: subscriptions,
            regCode: regCode
        }
        axios.post(registerUrl, requestBody, requestConfig).then(response => {
            setMessage('Registration Successful');
            //console.log(response);
        }).catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                setMessage(error.response.data.message);
            } else{
                setMessage('sorry... the backend server is down!! please try again later');
            }
        })
    }

    return (
/*
        <div>
            <form onSubmit={submitHandler}>
                <h5>Register</h5>
                name: <input type="text" value={name} onChange={event => setName(event.target.value)} /> <br/>
                email: <input type="text" value={email} onChange={event => setEmail(event.target.value)} /> <br/>
                username: <input type="text" value={username} onChange={event => setUsername(event.target.value)} /> <br/>
                password: <input type="password" value={password} onChange={event => setPassword(event.target.value)} /> <br/>
                <input type="submit" value="Register" />
            </form>
            {message && <p className="message">{message}</p>}
        </div>
*/
        <Stack spacing={2}>
        <Row>
            <Col>
                <img src={Logo} style={{ width: "250px" }} class="center" alt="Logo"/> 
                <hr />
                <h5 className="text-info text-center">Registration</h5>
                <hr />
                <form onSubmit={submitHandler}>
                    <Stack direction='column' spacing={2}>
                        <TextField label='name' type='text' required='true' onChange={event => setName(event.target.value)}/> 
                        <TextField label='phone' type='text' onChange={event => setPhone(event.target.value)}/>
                        <TextField label='email' type='text' required='true' onChange={event => setEmail(event.target.value)}/>
                        <TextField label='organization' type='text' onChange={event => setOrg(event.target.value)}/>
                        <TextField label='address' type='text' onChange={event => setAddress(event.target.value)}/>
                        <TextField label='username' type='text' required='true' onChange={event => setUsername(event.target.value)}/>
                        <TextField label='password' name='password' type='password' required='true' onChange={handleOnChange}/>
                        <TextField label='reenter password' name='passwordAgain' type='password' required='true' onChange={handleOnChange}/>
                        <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="subscriptions">Subscriptions</InputLabel>
                        <Select
                          labelId="subscription-checkbox-label"
                          id="subscription-checkbox"
                          name="subscriptions"
                          multiple
                          value={subscriptions}
                          onChange={handleOnChange}
                          input={<OutlinedInput label="Subscription"/>}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={MenuProps}
                          
                        >
                          {subscriptionNames.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox checked={subscriptions.indexOf(name) > -1} />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                        </FormControl>
                        <TextField label='Registration Code' type='text' required='true' onChange={event => setRegCode(event.target.value)}/>
                        <Button variant="contained" type="submit" disabled={Object.values(passwordError).includes(false)}>Register</Button>
                  
                    </Stack>
                </form>
                <hr />
                {message && <p className="message">{message}</p>}
            </Col>
        </Row>

        <Form.Text>
          {!passwordError.passwordAgain && (
            <div className="text-danger mb-0 h6">Password doesn't match!</div>
          )}
        </Form.Text>

            <ul className="mb-0 h6 " align="left">
              <li
                className={
                  passwordError.isLengthy ? "text-success" : "text-danger"
                }
              >
                Min 8 characters
              </li>
              <li
                className={
                  passwordError.hasUpper ? "text-success" : "text-danger"
                }
              >
                At least one upper case
              </li>
              <li
                className={
                  passwordError.hasLower ? "text-success" : "text-danger"
                }
              >
                At least one lower case
              </li>
              <li
                className={
                  passwordError.hasNumber ? "text-success" : "text-danger"
                }
              >
                At least one number
              </li>
              <li
                className={
                  passwordError.hasSpclChr ? "text-success" : "text-danger"
                }
              >
                At least one special character{" "}
              </li>
            </ul>

        </Stack>
    )
}

export default RegisterForm;