import React, { Fragment } from "react";
import { Title } from 'react-admin';
import {useState, useRef} from 'react';
import { Link } from "react-router-dom";
import Logo from "./dist/images/tc-logo-fordarkbg.png";
import bgImg from "./dist/images/bg-lines-withCollage.jpg";
import TC_Explainer_Video from "./dist/videos/TechComb_Automation_Manager_Explainer.mp4";
import screenfull from 'screenfull';
import ReactPlayer from 'react-player';
import Container from '@mui/material/Container';
import ControlIcons from './components/ControlIcons';

import "./styles/Home.css";
import "./dist/css/style.min.css";


const format = (seconds) => {
  if (isNaN(seconds)) {
    return '00:00'
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");

  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`
  } else {
      return `${mm}:${ss}`
  }
};


function Home() {
    document.title = "TechComb Inc."; 

    const [playerstate, setPlayerState] = useState({
      playing: false,
      muted: false,
      volume: 0.5,
      playerbackRate:1.0,
      played:0,
      seeking: false,
    })
  
    //Destructure State in other to get the values in it
    const { playing, muted, volume, playerbackRate, played, seeking } = playerstate;
    const playerRef = useRef(null);
    const playerDivRef = useRef(null);
  
    //This function handles play and pause onchange button
    const handlePlayAndPause = () => {
      setPlayerState({...playerstate, playing: !playerstate.playing})
    }
  
    const handleMuting = () => {
      setPlayerState({...playerstate, muted: !playerstate.muted})
    }
  
    const handleRewind = () => {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10)
    }
  
    const handleFastForward = () => {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() + 30)
    }
  
    const handleVolumeChange = (e, newValue) => {
      setPlayerState({...playerstate, volume:parseFloat(newValue/100), muted:newValue === 0 ? true : false, });
    }
  
    const handleVolumeSeek = (e, newValue) => {
      setPlayerState({...playerstate, volume:parseFloat(newValue/100), muted:newValue === 0 ? true : false, });
    }
  
    const handlePlayerRate = (rate) => {
      setPlayerState({...playerstate, playerbackRate: rate});
    }
  
    const handleFullScreenMode = () => {
      screenfull.toggle(playerDivRef.current)
    }
  
    const handlePlayerProgress = (state) => {
      console.log('onProgress', state);
      if (!playerstate.seeking) {
        setPlayerState({...playerstate, ...state});
      }
      console.log('afterProgress', state);
    }
  
    const handlePlayerSeek = (e, newValue) => {
      setPlayerState({...playerstate, played: parseFloat(newValue / 100)});
      playerRef.current.seekTo(parseFloat(newValue / 100));
      // console.log(played)
    }
  
    const handlePlayerMouseSeekDown = (e) => {
      setPlayerState({...playerstate, seeking: true});
    }
  
    const handlePlayerMouseSeekUp = (e, newValue) => {
      setPlayerState({...playerstate, seeking: false});
      playerRef.current.seekTo(newValue / 100);
    }
  
    const currentPlayerTime = playerRef.current ? playerRef.current.getCurrentTime() : '00:00';
    const movieDuration =  playerRef.current ? playerRef.current.getDuration() : '00:00';
    const playedTime = format(currentPlayerTime);
    const fullMovieTime = format(movieDuration);    


  return (
    <>
    <Title title="TechComb Inc." />
    {/*!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss --
  <!-- ============================================================== -->*/}
    <div className="page-wrapper overflow-hidden">
    <div className="home" style={{ backgroundImage: `url(${bgImg})`}}>
    <div className="headerContainer">
      <div class="row">
        <div>
          <img src= {Logo} alt="logo" />
        </div>
        <div class="col-lg-5">
          {/* <h1>
            New Age Robotic Automation
            <span class="text-info"> Powered by Artificial Intelligence </span>
              to Meet the Demands of Time
          </h1> */}

          <h1>
            <span class="text-info"> Machine vision software to meet the needs of your unique manufacturing facility </span>
          </h1> 
          <p class="py-4">
          <span class="fw-bold"> Personalized conveyance & robotics models that account for variables in your environment </span>
          </p>


{/*           <p class="py-4">
            We Offer<span class="fw-bold"> Vibrantly Creative &amp; Optimally Customized Solutions </span>
            to Complex Automation Needs through
            <span class="fw-bold"> Consistent &amp; Disciplined </span> Approach, Delivering
            <span class="fw-bold"> Seamless System and Process Integration </span> for Maximum Efficiency.
          </p> */}
          {/* <div class="down-btn dm-btn">

            <Link to="/Login" class="btn btn-info mb-10 px-5 py-3"> Dashboard </Link>
            <br></br>
            <br></br>
          </div>         */} 
        </div>
        <div class="col-lg-7">
        </div>
      </div>      
    </div>      
    </div>
    </div>

    <Container>
            <div className='playerDiv' ref={playerDivRef}>
                <ReactPlayer width={'100%'} height={'100%'}
                ref={playerRef} 
                url={TC_Explainer_Video}
                playing={playing}
                volume={volume} 
                playbackRate={playerbackRate}
                onProgress={handlePlayerProgress}
                muted={muted}/>

                <ControlIcons
                key={volume.toString()}
                playandpause={handlePlayAndPause}
                playing={playing}
                //rewind={handleRewind}
                //fastForward={handleFastForward}
                muting={handleMuting}
                muted={muted}
                volumeChange={handleVolumeChange}
                volumeSeek={handleVolumeSeek}
                volume={volume}
                playerbackRate={playerbackRate}
                playRate={handlePlayerRate}
                fullScreenMode={handleFullScreenMode}
                played={played}
                onSeek={handlePlayerSeek}  
                onSeekMouseUp={handlePlayerMouseSeekUp}
                onSeekMouseDown={handlePlayerMouseSeekDown}
                playedTime={playedTime}
                fullMovieTime={fullMovieTime}
                seeking={seeking}
                />
            </div>
          <p>Prepared by TechComb Inc.</p>      
          </Container>


    <div id="main-wrapper" class="overflow-hidden">
    
          <div class="container my-5 py-5">
            {/* <small class="text-info">Almost Covered Everything</small> */}
            {/* <h2 class="display-7">End-to-End System Integration Provided</h2> */}
            <h2 class="display-7">
              Reduce months of work - from data scrubbing and labeling to model selection, training, evaluation and iteration - down to as little as a few days with TechComb.
            </h2>
            <div class="row mt-4">
              <div class="col-lg-3 col-md-6 my-5">
                <span>
                  <i class="display-6 bi bi-eye text-info"></i>
                </span>
                <h4 class="mt-3">Data Capture</h4>
                <p>
                Collaborate with us to input your raw data.
                </p>
              </div> 

              <div class="col-lg-3 col-md-6 my-5">
                <span>
                  <i class="display-6 bi bi-cpu text-info"></i>
                </span>
                <h4 class="mt-3">Concierge Model Development</h4>
                <p>
                Our software builds a demo model.
                </p>
              </div> 

              <div class="col-lg-3 col-md-6 my-5">
                <span>
                  <i class="display-6 bi bi-robot text-info"></i>
                </span>
                <h4 class="mt-3">Test, Iterate, and Deploy </h4>
                <p>
                Executable files generated for selling your use-case to production grade models for deployment.
                </p>
              </div> 

{/*               <div class="col-lg-3 col-md-6 my-5">
                <span>
                  <i class="bi bi-robot text-info display-6"></i>
                </span>
                <h4 class="mt-3">Robotic Automation</h4>
                <p>
                  A wide range of process automation services involving robotic manipulation and computer vision.
                </p>
              </div>

              <div class="col-lg-3 col-md-6 my-5">
                <span>
                  <i class="display-6 bi bi-infinity text-info"></i>
                </span>
                <h4 class="mt-3">Artificial Intelligence</h4>
                <p>
                  Cutting-edge machine learning solutions for real-time object detection and tracking.
                </p>
              </div>

              <div class="col-lg-3 col-md-6 my-5">
                <span>
                  <i class="display-6 bi bi-eye text-info"></i>
                </span>
                <h4 class="mt-3">Quality Control</h4>
                <p>
                  Customized inspection systems for inline detection and separation of defective products.
                </p>
              </div>

              <div class="col-lg-3 col-md-6 my-5">
                <span>
                  <i class="display-6 bi bi-cpu text-info"></i>
                </span>
                <h4 class="mt-3">Edge Computing</h4>
                <p>
                  Embedded elctronics supporting massively parallel computing for on-the-edge IoT application deployment.
                </p>
              </div> */}

              <div class="text-center">
                <Link to="/ContactUs" class="btn btn-lg btn-success mt-4"> Contact Us </Link>
              </div>
            </div>
          </div>
    </div>


    </>
  );
  }

export default Home;