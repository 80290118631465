//import namor from 'namor';

const range = (len) => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

const newVendor = () => {
  const statusChance = Math.random();
  return {
    seller: "Golf Ball Planet",
    marketplace: "Amazon",
    asin: "B0CK5B2YN3",
    model: "Pro V1x 2023",
    grade: "Pro V1x 2023",
    quantity: 45,
    price: "$39.99",
    availability: 10,
    rating: "80%",
    link: "Link to the Amazon Site",
  };
};

export function makeData(len = 50) {
  return range(len).map(() => {
    return {
      ...newVendor(),
      children: range(10).map(newVendor),
    };
  });
}

const vendordata = [
  {
    seller: "Golf Ball Nut",
    marketplace: "Amazon",
    asin: "B0CK5B2YN3",
    model: "Titleist Trufeel",
    grade: "5A",
    quantity: 48,
    price: "$66.79",
    availability: 10,
    rating: "88%",
    link: <a href="https://www.amazon.com/GolfBall-Nut-Recycled-Titleist-Included/dp/B0CK5BNCQR/ref=sr_1_45?crid=18ELDQPBDFHZB&keywords=titleist%2Btrufeel%2Bused&qid=1697467466&sprefix=%2Caps%2C82&sr=8-45&th=1" target="_blank">Link</a>,
  },
  {
    seller: "Golf Ball Planet",
    marketplace: "Amazon",
    asin: "B09TG7GCN5",
    model: "Titleist Trufeel",
    grade: "4A",
    quantity: 50,
    price: "$54.99",
    availability: 0,
    rating: "89%",
    link: <a href="https://www.amazon.com/Golf-Ball-Planet-TruFeel-Balls/dp/B09TG533BL/ref=sr_1_1_sspa?crid=18ELDQPBDFHZB&keywords=titleist%2Btrufeel%2Bused&qid=1697467466&sprefix=%2Caps%2C82&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1&psc=1" target="_blank">Link</a>,
  },
  {
    seller: "Golf Ball Monkey",
    marketplace: "Amazon",
    asin: "B0BM6P3ZDS",
    model: "Titleist TruFeel",
    grade: "4A",
    quantity: 50,
    price: " $52.99 ",
    availability: 11,
    rating: "70%",
    link: <a href="https://www.amazon.com/Golf-Ball-Monkey-Trufeel-Recycled/dp/B0BM6P3ZDS/ref=sr_1_5?crid=18ELDQPBDFHZB&keywords=titleist%2Btrufeel%2Bused&qid=1697467466&sprefix=%2Caps%2C82&sr=8-5&th=1" target="_blank">Link</a>,
  },
  {
    seller: "Bastex",
    marketplace: "Amazon",
    asin: "B0B4Q9ZHYH",
    model: "Titleist TruFeel",
    grade: "3-Good",
    quantity: 48,
    price: " $54.99 ",
    availability: 12,
    rating: "65%",
    link: <a href="https://www.amazon.com/Recycled-Golf-Balls-Titleist-Trufeel/dp/B0B4PRW2T2/ref=sr_1_3?crid=18ELDQPBDFHZB&keywords=titleist%2Btrufeel%2Bused&qid=1697467466&sprefix=%2Caps%2C82&sr=8-3&th=1" target="_blank">Link</a>,
  },
  {
    seller: "Golf Ball Monkey",
    marketplace: "Amazon",
    asin: "B0BM6QS492",
    model: "Titleist TruFeel",
    grade: "3A",
    quantity: 50,
    price: " $45.99 ",
    availability: 13,
    rating: "70%",
    link: <a href="https://www.amazon.com/Golf-Ball-Monkey-Trufeel-Recycled/dp/B0BM6S2YDR/ref=sr_1_6?crid=18ELDQPBDFHZB&keywords=titleist%2Btrufeel%2Bused&qid=1697467466&sprefix=%2Caps%2C82&sr=8-6&th=1" target="_blank">Link</a>,
  },
  {
    seller: "Golf Ball Monkey",
    marketplace: "Amazon",
    asin: "B0BM6QS492",
    model: "Titleist TruFeel",
    grade: "3A",
    quantity: 24,
    price: " $17.99 ",
    availability: 0,
    rating: "70%",
    link: <a href="https://www.amazon.com/Golf-Ball-Monkey-Trufeel-Recycled/dp/B0BM6QS492/ref=sr_1_1?keywords=B0BM6QS492&qid=1701302218&sr=8-1&th=1" target="_blank">Link</a>,
  },
  {
    seller: "Golf Ball Planet",
    marketplace: "Amazon",
    asin: "B09NP8TS9Z",
    model: "Titleist TruFeel",
    grade: "3A",
    quantity: 50,
    price: " $45.99 ",
    availability: 1,
    rating: "89%",
    link: <a href="https://www.amazon.com/Golf-Ball-Planet-TruFeel-Titleist/dp/B09NP9NY7Q/ref=sr_1_7?crid=18ELDQPBDFHZB&keywords=titleist%2Btrufeel%2Bused&qid=1697467466&sprefix=%2Caps%2C82&sr=8-7&th=1&psc=1" target="_blank">Link</a>,
  },
]

const treedata = [
  {
    model_name: 'Roadway Objects',
    revision: 'rev 1.1',
    build_date: '11/5/2023',
    train_data_count: 24000,
    no_of_classes: 67,
    trained_step_count: 255000,
    batch_size: 8,
    image_size: '512x512',
    model_architecture: 'EfficientDet-D0',
    training_duration: '2d 8h 38m 47s',
    revision_note: 'added DT and Tour-soft models',
    download_link: <a href="#" target="_blank">Download Link</a>,
  },
  {
    model_name: 'Pastry Types',
    revision: 'rev 2.0',
    build_date: '12/8/2023',
    train_data_count: 24000,
    no_of_classes: 18,
    trained_step_count: 314000,
    batch_size: 8,
    image_size: '512x512',
    model_architecture: 'EfficientDet-D0',
    training_duration: '2d 16h 56m 15s',
    revision_note: 'added nms',
    download_link: <a href="#" target="_blank">Download Link</a>,
  },
]

export { vendordata, treedata };
