import React, { useState } from "react";
import LoginForm from "./Login-comp";
//import { ResetPassword } from "../../components/password-reset/PasswordReset.comp";
// import "./styles/style.css";
// import "./styles/bootstrap.min.css";
// import "./styles/fonts.css";
import "./styles/Login.css";

const Login = () => {
	const [frmLoad, setFrmLoad] = useState("login");

//	const handleOnResetSubmit = e => {
//		e.preventDefault();
//	};

	const formSwitcher = frmType => {
		setFrmLoad(frmType);
	};

	return (
		<div className="login-page">
			<div className="jumbotron form-box">
            {/* <Jumbotron className="form-box"> */}

				{frmLoad === "login" && <LoginForm formSwitcher={formSwitcher} />}

{/* 				{frmLoad === "rest" && (
					<ResetPassword
						// handleOnChange={handleOnChange}
						handleOnResetSubmit={handleOnResetSubmit}
						formSwitcher={formSwitcher}
						// email={email}
					/>
				)} */}
			</div>
		</div>
	);
};

export default Login;