import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getToken } from "../service/AuthService";

const PublicRoutes = () => {
    return (
        !getToken() ? <Outlet /> : <Navigate to='/Customer' />
    )
}

export default PublicRoutes;