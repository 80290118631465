import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardGroup,
  Button,
  Row,
  Col,
  CardLink,
  CardHeader,
  CardFooter,
  CardImgOverlay,
} from 'reactstrap';
import { useNavigate } from "react-router-dom";
import subscriptionNames from "./subscriptionData";
import { getUser } from "../../service/AuthService";

const Cards = () => {
  
  const user = getUser();
	const name = user !== 'undefined' && user ? user.name : '';
	const username = user !== 'undefined' && user ? user.username : '';
	const subscriptions = user !== 'undefined' && user ? user.subscriptions : '';
	const subscriptionList = subscriptions.split(',');

  let history = useNavigate();

  const subscribeHandler = () => {
    history('/Contactus');
  }

  const tutorialHandler = () => {
    history('/Contactus');
  }  
  
  return (
    <div>
      {/* --------------------------------------------------------------------------------*/}
      {/* Card-2*/}
      {/* --------------------------------------------------------------------------------*/}
      <Row>
        <h5 className="mb-3 mt-3">Your Active Subscriptions</h5>

        {subscriptionNames.map((name) => (
          <>
            {subscriptionList.includes(name) ? (
            <Col md="6" lg="3">
              <Card body color="light-success">
                <CardTitle tag="h5">{name}</CardTitle>
                <div>
                  <Button onClick={tutorialHandler}>View Tutorial</Button>
                </div>
              </Card>            
            </Col>
          ) : (
            <Col md="6" lg="3">
              <Card body color="light-danger">
                <CardTitle tag="h5">{name}</CardTitle>
                <div>
                  <Button onClick={subscribeHandler}>Subscribe Now</Button>
                </div>
              </Card>            
            </Col>
          )}
        </>
        ))}
      </Row>

    </div>
  );
};

export default Cards;