import React, { useState } from "react";
import RegisterForm from "./Register-comp";

import "./styles/Login.css";

const Register = () => {
	const [frmLoad, setFrmLoad] = useState("register");

//	const handleOnResetSubmit = e => {
//		e.preventDefault();
//	};

	const formSwitcher = frmType => {
		setFrmLoad(frmType);
	};

	return (
		<div className="login-page">
			<div className="jumbotron form-box">
            {/* <Jumbotron className="form-box"> */}
				{frmLoad === "register" && <RegisterForm formSwitcher={formSwitcher} />}

{/* 				{frmLoad === "rest" && (
					<ResetPassword
						// handleOnChange={handleOnChange}
						handleOnResetSubmit={handleOnResetSubmit}
						formSwitcher={formSwitcher}
						// email={email}
					/>
				)} */}
			</div>
		</div>
	);
};

export default Register;